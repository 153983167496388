.dataTable{
  padding-top: 25px;
  padding-bottom: 25px;
  .saveButton{
    width: 170px;
    height: 50px;
    font-size: 30px;

    background-color: green;
    color: white;
  }
  .dataGrid{
    background: white;
    padding: 20px;

    .MuiDataGrid-toolbarContainer{
      flex-direction: row-reverse;
    }

    //img{
    //  width: 32px;
    //  height: 32px;
    //  border-radius: 50%;
    //  object-fit: cover;
    //}

    .action{
      display: flex;
      gap: 15px;

      .deleteButton{
        background-color: red;
        color: white;
        cursor: pointer;
      }
      //img{
      //  width: 20px;
      //  height: 20px;
      //  cursor: pointer;
      //}
    }
  }
}