@import "../../styles/variables.scss";


.datePicker{
  //display: flex;
  background-color: #575E77;
  color: #ffffff;
  border-color: #ffffff;
  label{
    color: #ffffff;
  }
  input{
    color: #ffffff;
  }
}

