.navbar{
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logout{
    align-items: center;
    cursor: pointer;

    button{
      cursor: pointer;
    }
  }
}