
.userAnalytics{
  span{
    font-size: 30px;
    text-decoration: underline;
  }

  margin-bottom: 50px;
}

.funnelAnalytics{
  span{
    font-size: 30px;
    text-decoration: underline;
  }
}