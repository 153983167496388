body{
  //width: 100%;
  //overflow-x: hidden;
  //overflow-y: scroll;
  //width: auto;
}

.barChartDiv{
  border: 1px solid white;
  width: 85%;
  padding-top: 20px;
  overflow-x: scroll;
}