@import "../../styles/variables.scss";

.formControl{
  color: #ffffff;
  border-color: #ffffff;
  .inputLabel{
    color: #ffffff;
    border-color: #ffffff;
  }
  .select{
    color: #ffffff;
    border-color: #ffffff;
    background-color: #575E77;
    .menuItem{
      color: #ffffff;
      border-color: #ffffff;
    }
  }
}