@import "../../styles/variables.scss";

.menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  .listItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;

    &:hover {
      background-color: $soft-bg-color;
    }
  }
}