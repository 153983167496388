@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.main{
  font-family: "Inter", sans-serif;
  background-color: $main-bg-color;
  color: $main-text-color;
}

.container{
  display: flex;
}

.menuContainer {
  width: 250px;
  padding: 5px 20px;
  border-right: 2px solid $soft-bg-color;
}

.contentContainer {
  padding: 5px 20px;
  width: 90%;
  height: 1000px;
}